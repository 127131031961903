import React from 'react'
import ErrorModal from '../ErrorModal'
import QuestionsModal from '../QuestionsModal/QuestionsModalNew.jsx'
import ReasonForVisitModal from '../ReasonForVisitModal'
import Modal from '../../../shared/components/Modal'
import noticeIcon from '../../../shared/assets/info.svg'
import { OBGYNReasonsForVisit } from '../../../shared/constants/messages'
import './obgyn-questionaire.scss'
import { OBGYNSchedulingStartedEvent, OBGYNSchedulingFailedEvent, scheduleQuestionEvent } from '../../../shared/utility-functions/googleTagManager.js'
import { OBGYN_WELL_WOMAN, OBGYN_NEW_PATIENT } from '../../../shared/constants/api-helpers'
import URLS from '../../../shared/constants/urls.js'
import phoneIcon from '../../../shared/assets/phone-blue.svg'


export default class OBGYNQuestionaire extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      steps: [1],
      modalToShow: 1,
      error: null,
      visitType: window.sessionStorage.getItem('VisitTypeID'),
      reasonsForVisit: [],
      currentQuestion: 0,
    }
    this.inner = this.inner.bind(this)
    this.addReasonForVisit = this.addReasonForVisit.bind(this)
    this.removeReasonForVisit = this.removeReasonForVisit.bind(this)
    this.addSteps = this.addSteps.bind(this)
    this.moveToNextStep = this.moveToNextStep.bind(this)
    this.submissionHandler = this.submissionHandler.bind(this)
    this.defaultErrorMessage = this.defaultErrorMessage.bind(this)
    this.bleedingService = this.bleedingService.bind(this)
    this.painScaleService = this.painScaleService.bind(this)
    this.possiblyPregnantService = this.possiblyPregnantService.bind(this)
    this.specialAssistanceService = this.specialAssistanceService.bind(this)
    this.financialWarningService = this.financialWarningService.bind(this)
    this.hideFunction = this.hideFunction.bind(this)
  }

  componentDidMount() {
    OBGYNSchedulingStartedEvent()
  }

  addReasonForVisit(reason) {
    this.setState({ reasonsForVisit: [...this.state.reasonsForVisit, reason] })
  }

  removeReasonForVisit(reason) {
    this.setState({
      reasonsForVisit: this.state.reasonsForVisit.filter(function (reasonForVisit) {
        return reasonForVisit !== reason
      })
    })
  }

  addSteps(additionalSteps) {
    this.setState({ steps: [...this.state.steps, ...additionalSteps] })
  }

  moveToNextStep() {
    const nextStep = this.state.currentStep + 1
    this.setState({ currentStep: nextStep })
    this.setState({ modalToShow: this.state.steps[nextStep] })
  }

  defaultErrorMessage(phone) {
    let reasons = ''
    for (let i = 0; i < this.state.reasonsForVisit.length; i++) {
      reasons += this.state.reasonsForVisit[i]
      reasons += i < this.state.reasonsForVisit.length - 1 ? ', ' : ''
    }
    OBGYNSchedulingFailedEvent(reasons)

    return {
      icon: noticeIcon,
      title: 'Next Steps',
      text: `In order to determine the best plan of care for you, please call ${phone ? `us at <a className='modal-phone' href='tel:${phone}'>${phone}</a>` : ''}`
    }
  }

  bleedingService(phone) {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        question: 'Are you bleeding through more than one pad/tampon per hour, potentially pregnant or post-menopausal?',
        answers: [{ text: 'no', className: 'bleeding-question-no' }, { text: 'yes', error: this.defaultErrorMessage(phone), className: 'bleeding-question-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  painScaleService(phone) {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        question: 'On a pain scale of 0-10, with 0 being low and 10 being high, is your pain above a 7?',
        answers: [{ text: 'no', className: 'pain-question-no' }, { text: 'yes', error: this.defaultErrorMessage(phone), className: 'pain-question-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  possiblyPregnantService(phone) {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        question: 'Is there a chance you are pregnant?',
        answers: [{ text: 'no', className: 'possibly-pregnant-question-no' }, { text: 'yes', error: this.defaultErrorMessage(phone), className: 'possibly-pregnant-question-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  bumpsService(phone) {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        question: 'Do you have any bumps/sores?',
        answers: [{ text: 'no', className: 'bumps-soreness-question-no' }, { text: 'yes', error: this.defaultErrorMessage(phone), className: 'bumps-soreness-question-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  financialWarningService() {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        type: 'notice',
        title: 'Please Note',
        question: 'Depending on your insurance coverage, you may have financial obligation if it has been less than one year since your last annual exam.',
        answers: [{ text: 'I UNDERSTAND', className: 'financial-obligation-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  birthControlService() {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        type: 'notice',
        title: 'Please Note',
        question: 'This visit is to discuss options of birth control with the provider, not for insertion or removal. For all other birth control appointments, please contact the office to schedule.',
        answers: [{ text: 'I UNDERSTAND', className: 'financial-obligation-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: () => this.moveToNextStep()
    }
  }

  specialAssistanceService() {
    return {
      getCurrentQuestion: () => ({
        id: 1,
        question: 'Do you require any special assistance during your visit - like an interpreter or assistive devices?',
        answers: [{ text: 'no', className: 'special-accomodations-no' }, { text: 'yes', className: 'special-accomodations-yes' }]
      }),
      numberOfQuestions: 1,
      answerQuestion: (answer) => {
        if (answer.text === 'yes') {
          this.props.setSpecialAssistance(true)
        } else {
          this.props.setSpecialAssistance(false)
        }
        this.props.setReasonsForVisit(this.state.reasonsForVisit)
        this.setState({ modalToShow: 0 })
        window.sessionStorage.setItem('answeredOBGYN', 'true')
      }
    }
  }

  obSchedulingService(numberOfQuestions) {
    return {
      getCurrentQuestion: () => {
        let question = {};
        switch (this.state.modalToShow) {
          case 1:
            question = {
              id: 1,
              number: 1,
              question: 'Have you seen this doctor in the past three years?',
              answers: [{ text: 'no' }, { text: 'yes' }]
            }
            break;
          case 9:
            question = {
              id: 1,
              number: this.state.currentQuestion,
              question: '',
              answers: [{ text: 'no' }, { text: 'yes' }]
            }
            if (this.state.currentQuestion == 1) {
              question.question = 'Have you had a positive pregnancy test?'
            }
            else if (this.state.currentQuestion == 2) {
              question.question = 'Are you currently experiencing abdominal pain or heavy vaginal bleeding?'
            }
            else if (this.state.currentQuestion == 3) {
              question.question = 'Have you been seen by a different healthcare provider for your current pregnancy?'
            }
            else if (this.state.currentQuestion == 4) {
              question = {
                id: 1,
                number: this.state.currentQuestion,
                question: 'Please enter the first day of your last period/menstrual cycle, if known.',
                answers: [{ text: `I'M NOT SURE` }, { text: 'CONTINUE', required: true }],
                onDateChange: this.dateHandler,
                input: 'date'
              }
            }
            break;
          case 10:
            question = {
              id: 1,
              number: 1,
              question: 'Your next step is to speak with a clinical associate by phone. They will gather routine info, answer any questions and help you schedule your first in-person appointment with a provider. Note: the time you select here is for the pre-visit phone call.',
              answers: [{ text: 'I UNDERSTAND' }],
              image: phoneIcon
            }
            break;
          case 11:
            question = {
              id: 1,
              number: 1,
              question: '',
              type: 'notice',
              answers: [{ text: 'CLOSE' }],
            }
            if (this.state.currentQuestion == 2) {
              question.question = 'Please call our office to continue the scheduling process. If after hours, please go to your closest emergency department.'
            }
            else if (this.state.currentQuestion == 3) {
              question.question = 'Please call our office to continue the scheduling process.'
            }
            else if (this.state.currentQuestion == 4) {
              question.question = 'Please call the office number for appropriate next steps.'
            }
            break;

          default:
            break;
        }
        return question;
      },
      numberOfQuestions,
      answerQuestion: (answer) => {
        switch (this.state.modalToShow) {
          case 1:
            if (answer.text === 'yes') {
              window.open(URLS.mychartApptUrl, '_blank')
            } else {
              this.setState({ modalToShow: 9 })
              this.setState({ currentQuestion: 1 })
            }
            break;
          case 9:
            if (this.state.currentQuestion === 1) {
              if (answer.text === 'no') {
                window.sessionStorage.removeItem('isOBS')
                window.sessionStorage.setItem('isOBOpen', 'true')
                window.sessionStorage.setItem('VisitType', OBGYN_NEW_PATIENT)
                window.sessionStorage.removeItem('scheduleDate');
                window.sessionStorage.setItem('visitDuration', '15')
              } else {
                window.sessionStorage.setItem('isOBS', 'true')
                window.sessionStorage.setItem('visitDuration', '45')
              }
              this.setState({ modalToShow: answer.text === 'yes' ? 10 : 12 })
              this.setState({ currentQuestion: this.state.currentQuestion == 4 ? 4 : this.state.currentQuestion + 1 })
            } else {
              if (['yes', `I'M NOT SURE`].includes(answer.text)) {
                this.setState({ modalToShow: 11 })
              }
              else if (answer.text == 'CONTINUE') {
                const currentDate = new Date();
                const dateDiff = currentDate - answer.inputDate;
                const noOfWeeks = Math.round(dateDiff / (1000 * 60 * 60 * 24 * 7));
                if (noOfWeeks > 16) {
                  this.setState({ modalToShow: 11 })
                } else {
                  if (noOfWeeks <= 6) {
                    let newDate = answer.inputDate;
                    newDate.setDate(newDate.getDate() + (6 * 7));
                    window.sessionStorage.setItem('scheduleDate', newDate);
                  }
                  else{
                    this.props.setSpecialAssistance(true)
                    this.setState({ modalToShow: 0 })
                    window.sessionStorage.setItem('answeredOBGYN', 'true')
                  }
                  this.props.setSpecialAssistance(true)
                  this.setState({ modalToShow: 0 })
                  window.sessionStorage.setItem('answeredOBGYN', 'true')
                  this.props.fetchAppointments()
                }
                if(this.props.setRenderTimeSlots) this.props.setRenderTimeSlots(true);
              }
              else {
                this.setState({ modalToShow: 9 })
                this.setState({ currentQuestion: this.state.currentQuestion == 4 ? 4 : this.state.currentQuestion + 1 })
              }
            }
            break;
          case 10:
            this.setState({ modalToShow: 9 })
            // this.setState({ currentQuestion: 2 })
            break;
          case 11:
            this.hideFunction();
            break;
          default:
            break;
        }
      }
    }
  }

  dateHandler(evt) {
    const inputDate = new Date(evt.target.value);
    this.setState({ inputDate: new Date(inputDate) })
  }

  submissionHandler(action) {
    if (action && action.error) {
      action.error.icon = noticeIcon
      this.setState({ error: action.error })
      if (action.error.title && action.error.title === 'Next Steps') {
        this.props.setPreliminaryStatus && this.props.setPreliminaryStatus(false)
      }
    } else if (action && action.reasonComplete === true) {
      const dataLayerObject = {
        flow: 'OBGYN',
        scheduleQuestionNumber: undefined,
        scheduleQuestion: 'Reason For Visit',
        scheduleQuestionAnswer: 'continue'
      }
      scheduleQuestionEvent(dataLayerObject)
      this.props.setVisitTypeID(OBGYN_NEW_PATIENT)

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.PREGNANT)) {
        this.setState({ error: this.defaultErrorMessage(this.props.phone) })
        this.props.setPreliminaryStatus && this.props.setPreliminaryStatus(false)
        return
      }
      let additionalSteps = []

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.ABNORMAL_BLEEDING)) {
        additionalSteps.push(2)
      }

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.PELVIC_PAIN)) {
        additionalSteps.push(3)
        additionalSteps.push(4)
      }

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.VAGINAL_ISSUES)) {
        additionalSteps.push(5)
      }

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.ANNUAL_VISIT)) {
        additionalSteps.push(6)
      }

      if (this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.BIRTH_CONTROL)) {
        additionalSteps.push(7)
      }

      if (this.state.reasonsForVisit.length === 1 && this.state.reasonsForVisit[0] === OBGYNReasonsForVisit.ANNUAL_VISIT) {
        this.props.setVisitTypeID(OBGYN_WELL_WOMAN)
        window.sessionStorage.setItem('VisitType', OBGYN_WELL_WOMAN)
      }

      if (this.state.reasonsForVisit.length === 2 && this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.ANNUAL_VISIT) && this.state.reasonsForVisit.includes(OBGYNReasonsForVisit.BIRTH_CONTROL)) {
        this.props.setVisitTypeID(OBGYN_WELL_WOMAN)
      }

      additionalSteps.push(8)
      this.addSteps(additionalSteps)
      this.props.fetchAppointments()
    } else {
      let existing = window.sessionStorage.getItem('VisitTypeID')
      if (existing) {
        this.setState({ currentStep: 0 })
      } else {
        this.setState({
          error: {
            icon: noticeIcon,
            text: 'An error occurred. Please refresh the page and try again.'
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      document.activeElement.blur()
    }
    if (prevState.steps && this.state.steps && prevState.steps.length !== this.state.steps.length) {
      this.moveToNextStep()
    }
    if (this.state.modalToShow === 0) {
      this.props.setPreliminaryStatus && this.props.setPreliminaryStatus(true)
      this.props.hideSchedulingModal()
    }
  }

  hideFunction(popstate) {
    if (this.props.setShowQuestions) {
      this.props.setShowQuestions(false)
    }
    if (this.props.setShowPreliminaryQuestionsFalse) {
      this.props.setShowPreliminaryQuestionsFalse()
    }
    if ((popstate !== true) && (!this.props.from)) {
      window.history.back()
    }
  }

  inner() {
    if (!this.state.error) {
      switch (this.state.modalToShow) {
        case 1:
          return <QuestionsModal title={'Before You Schedule'} service={this.obSchedulingService(1)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 2:
          return <QuestionsModal title={'Tell us more'} service={this.bleedingService(this.props.phone)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 3:
          return <QuestionsModal title={'Tell us more'} service={this.painScaleService(this.props.phone)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 4:
          return <QuestionsModal title={'Tell us more'} service={this.possiblyPregnantService(this.props.phone)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 5:
          return <QuestionsModal title={'Tell us more'} service={this.bumpsService(this.props.phone)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 6:
          return <QuestionsModal service={this.financialWarningService()} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 7:
          return <QuestionsModal service={this.birthControlService()} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 8:
          return <QuestionsModal title={'Accommodation Needs'} service={this.specialAssistanceService()} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 9:
          return <QuestionsModal title={'Tell us more'} service={this.obSchedulingService(4)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 10:
          return <QuestionsModal title={'We’re excited to discuss how we can help during your pregnancy!'} service={this.obSchedulingService(1)} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 11:
          return <QuestionsModal title={'Next Steps'} service={this.obSchedulingService()} submissionHandler={this.submissionHandler} flow='OBGYN' />
        case 12:
          return <ReasonForVisitModal addReason={this.addReasonForVisit} removeReason={this.removeReasonForVisit} phone={this.props.phone} doctor={this.props.doctor} numberOfReasons={this.state.reasonsForVisit.length} submissionHandler={this.submissionHandler} />

        default:
          return <div>{this.state.modalToShow}</div>
      }
    } else {
      return <ErrorModal error={this.state.error} />
    }
  }

  render() {
    return (<Modal hideFunction={this.hideFunction} showModal={this.state.modalToShow > 0 || this.state.error}>
      {this.inner()}
    </Modal>)
  }
}
