
const MAMMO_LOCATIONS_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetMammoLocations'
const MAMMO_VISIT_REGULAR = '1341'
const MAMMO_VISIT_IMPLANTS = '1477'
const MAMMO_VISIT_ASSISTANCE = '1476'
const OBGYN_WELL_WOMAN = '1170025'
const OBGYN_NEW_PATIENT = '2415'
const NEW_PATIENT = '1003'
const CARDIO = '361'
const VIDEO_VISIT_ID = '1408'
const OBS_VISIT_ID = '1170024'
const DRIVE_UP_VISIT_ID = '2175'
const IN_PERSON_VISIT_ID = '32104'
const VIDEO_PROVIDER_ID = '241997'
// const UC_DEPARTMENT_ID = (process.env.NODE_ENV === "production") ? '1018106004' : '1019320002'
const UC_DEPARTMENT_ID = '1018106004'
const MAMMO_SERVICE_TYPE = 'Annual Mammogram Screening'
const MAMMO_VISIT_TYPES = [ MAMMO_VISIT_REGULAR, MAMMO_VISIT_IMPLANTS, MAMMO_VISIT_ASSISTANCE ]
const PROVIDER_COMMENTS_ENDPOINT = '/FindADoc/RatingsAndReviews.svc/rest/FetchReviews'
const PROVIDER_RATINGS_ENDPOINT = '/FindADoc/RatingsAndReviews.svc/rest/FetchRatingsForDoctors'
const FAD_AUTOCOMPLETE_ENDPOINT = '/api/findadoctor/autocomplete'
const FAD_PROVIDERS_ENDPOINT = '/api/findadoctor/get'
const CIN_AUTOCOMPLETE_ENDPOINT = '/api/findadoctor/cinautocomplete'
const CIN_PROVIDERS_ENDPOINT = '/api/findadoctor/getcin'
const LOCATION_AUTOCOMPLETE_ENDPOINT = '/api/findalocation/autocomplete'
const LOCATION_SEARCH_ENDPOINT = '/api/findalocation/get'
const ALL_LOCATIONS_ENDPOINT = '/Facilities/Locations.svc/rest/GetAllLocationsInfo'
const MAM_LOCATIONS_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetAllMammoLocations'
const INSURANCE_ENDPOINT = '/Information/InsuranceService.svc/rest/FetchAcceptedInsuranceList'
const CLASSES_ENDPOINT = '/api/classes/get'
const CLASSES_AUTOCOMPLETE_ENDPOINT = '/api/classes/GetPredictive'
const PROXIES_AND_PERMISSIONS_ENDPOINT = '/MOHLogin/PatientService.svc/rest/GetPermissions'
const PHYSICIAN_DETAILS_BY_NPI_ENDPOINT = '/api/findadoctor/GetByNPI'
const PHYSICIAN_DETAILS_BY_EPICID_ENDPOINT = '/api/findadoctor/GetByEpicId'
const APPOINTMENT_PROVIDERS_ENDPOINT = '/Appointments/ProviderService.svc/rest/GetAvailableProviders'
const SCHEDULE_A_DOCTOR_PROVIDER_ENDPOINT = '/api/findadoctor/GetOnlineScheduling'
const SERVICE_AUTOCOMPLETE_ENDPOINT = '/api/findaservice/autocomplete'
const SITE_SEARCH_ENDPOINT = '/api/sitesearch/get'
const SERVICES_ENDPOINT = '/api/findaservice/get'
const AVAILABLE_APPOINTMENTS_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetAvailableAppointmentTimes'
const AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetAvailableMammoAppointmentTimes'
const AVAILABLE_UC_APPOINTMENTS_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetUrgentCareVideoVisitAppointments'
const LOGIN_ENDPOINT = '/logincheck.asp'
const FETCH_TERMS_AND_CONDITIONS_ENDPOINT = '/MOH-Support/terms.htm'
const FETCH_PT_FROM_APPTID_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/VerifyReschedule'
const FETCH_PT_FROM_CGAPPTID_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetAppointmentLookup'
const FETCH_PT_FROM_CGAPPTID_ENDPOINT_EP = '/appointment-lookup'
const FETCH_APPOINTMENTS_FOR_RESCHEDULING_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/GetRescheduleAppointments'
const VERIFY_REACTIVATION_ENDPOINT = '/Appointments/AppointmentsService.svc/rest/VerifyReactivation'
const SEARCH_UC_REASON_FOR_VISIT = '/Appointments/AppointmentsService.svc/rest/SearchUrgentCareReasonForVisit'

export default FAD_AUTOCOMPLETE_ENDPOINT
export {
  MAMMO_LOCATIONS_ENDPOINT,
  MAMMO_VISIT_REGULAR,
  UC_DEPARTMENT_ID,
  MAMMO_VISIT_IMPLANTS,
  MAMMO_VISIT_ASSISTANCE,
  OBGYN_WELL_WOMAN,
  OBGYN_NEW_PATIENT,
  NEW_PATIENT,
  CARDIO,
  MAMMO_SERVICE_TYPE,
  MAMMO_VISIT_TYPES,
  PROVIDER_COMMENTS_ENDPOINT,
  PROVIDER_RATINGS_ENDPOINT,
  FAD_AUTOCOMPLETE_ENDPOINT,
  AVAILABLE_UC_APPOINTMENTS_ENDPOINT,
  FAD_PROVIDERS_ENDPOINT,
  CIN_AUTOCOMPLETE_ENDPOINT,
  CIN_PROVIDERS_ENDPOINT,
  LOCATION_AUTOCOMPLETE_ENDPOINT,
  INSURANCE_ENDPOINT,
  CLASSES_ENDPOINT,
  CLASSES_AUTOCOMPLETE_ENDPOINT,
  VIDEO_VISIT_ID,
  OBS_VISIT_ID,
  IN_PERSON_VISIT_ID,
  DRIVE_UP_VISIT_ID,
  VIDEO_PROVIDER_ID,
  LOCATION_SEARCH_ENDPOINT,
  ALL_LOCATIONS_ENDPOINT,
  MAM_LOCATIONS_ENDPOINT,
  PROXIES_AND_PERMISSIONS_ENDPOINT,
  PHYSICIAN_DETAILS_BY_NPI_ENDPOINT,
  PHYSICIAN_DETAILS_BY_EPICID_ENDPOINT,
  APPOINTMENT_PROVIDERS_ENDPOINT,
  SCHEDULE_A_DOCTOR_PROVIDER_ENDPOINT,
  SERVICE_AUTOCOMPLETE_ENDPOINT,
  SITE_SEARCH_ENDPOINT,
  SERVICES_ENDPOINT,
  AVAILABLE_APPOINTMENTS_ENDPOINT,
  AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT,
  LOGIN_ENDPOINT,
  FETCH_TERMS_AND_CONDITIONS_ENDPOINT,
  FETCH_PT_FROM_APPTID_ENDPOINT,
  FETCH_PT_FROM_CGAPPTID_ENDPOINT,
  FETCH_APPOINTMENTS_FOR_RESCHEDULING_ENDPOINT,
  VERIFY_REACTIVATION_ENDPOINT,
  FETCH_PT_FROM_CGAPPTID_ENDPOINT_EP,
  SEARCH_UC_REASON_FOR_VISIT
}
