export const obOnlineSchedulingConstants = [
  {
    DepartmentId: "1019162001",
    NurseId: "124520",
    ProviderId: "101332",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019162001",
    NurseId: "124520",
    ProviderId: "101237",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019162001",
    NurseId: "124520",
    ProviderId: "101321",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019162001",
    NurseId: "124520",
    ProviderId: "143575",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019162001",
    NurseId: "124520",
    ProviderId: "114405",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019121034",
    NurseId: "275368",
    ProviderId: "101195",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019121034",
    NurseId: "275368",
    ProviderId: "183768",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019121034",
    NurseId: "275368",
    ProviderId: "133578",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019121034",
    NurseId: "275368",
    ProviderId: "128525",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "100668",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "104803",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "237081",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "131723",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "171375",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "114391",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019324003",
    NurseId: "231116",
    ProviderId: "223765",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "100668",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "104803",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "237081",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "131723",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "171375",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "114391",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019153046",
    NurseId: "222495",
    ProviderId: "223765",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "100668",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "104803",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "237081",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "131723",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "171375",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "114391",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019274002",
    NurseId: "231113",
    ProviderId: "223765",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1020108008",
    NurseId: "145929",
    ProviderId: "191652",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1020108008",
    NurseId: "145929",
    ProviderId: "224525",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1020108008",
    NurseId: "145929",
    ProviderId: "110912",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1020108008",
    NurseId: "145929",
    ProviderId: "100258",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019181013",
    NurseId: "216388",
    ProviderId: "224969",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019181013",
    NurseId: "216388",
    ProviderId: "103999",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019181013",
    NurseId: "216388",
    ProviderId: "116026",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019181013",
    NurseId: "216388",
    ProviderId: "TBD",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019351002",
    NurseId: "208099",
    ProviderId: "100020",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019351002",
    NurseId: "208099",
    ProviderId: "191682",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019351002",
    NurseId: "208099",
    ProviderId: "114481",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019156013",
    NurseId: "206376",
    ProviderId: "117041",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019156013",
    NurseId: "206376",
    ProviderId: "128502",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019156013",
    NurseId: "206376",
    ProviderId: "115432",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019156013",
    NurseId: "206376",
    ProviderId: "223328",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019156013",
    NurseId: "206376",
    ProviderId: "153536",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019301002",
    NurseId: "206376",
    ProviderId: "117041",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019301002",
    NurseId: "206376",
    ProviderId: "128502",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019301002",
    NurseId: "206376",
    ProviderId: "115432",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019301002",
    NurseId: "206376",
    ProviderId: "223328",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019301002",
    NurseId: "206376",
    ProviderId: "153536",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019365001",
    NurseId: "255338",
    ProviderId: "103342",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019365001",
    NurseId: "255338",
    ProviderId: "231400",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019365001",
    NurseId: "255338",
    ProviderId: "102786",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019365001",
    NurseId: "255338",
    ProviderId: "104218",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019365001",
    NurseId: "255338",
    ProviderId: "127691",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019218033",
    NurseId: "255338",
    ProviderId: "103342",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019218033",
    NurseId: "255338",
    ProviderId: "231400",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019218033",
    NurseId: "255338",
    ProviderId: "102786",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019218033",
    NurseId: "255338",
    ProviderId: "104218",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019218033",
    NurseId: "255338",
    ProviderId: "127691",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019261002",
    NurseId: "178946",
    ProviderId: "100702",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019261002",
    NurseId: "178946",
    ProviderId: "127097",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019261002",
    NurseId: "178946",
    ProviderId: "101057",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019261002",
    NurseId: "178946",
    ProviderId: "235418",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019261002",
    NurseId: "178946",
    ProviderId: "100680",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019256001",
    NurseId: "124850",
    ProviderId: "100702",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019256001",
    NurseId: "124850",
    ProviderId: "127097",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019256001",
    NurseId: "124850",
    ProviderId: "101057",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019256001",
    NurseId: "124850",
    ProviderId: "235418",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019256001",
    NurseId: "124850",
    ProviderId: "100680",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019360003",
    NurseId: "252176",
    ProviderId: "100702",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019360003",
    NurseId: "252176",
    ProviderId: "127097",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019360003",
    NurseId: "252176",
    ProviderId: "101057",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019360003",
    NurseId: "252176",
    ProviderId: "235418",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019360003",
    NurseId: "252176",
    ProviderId: "100680",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019329001",
    NurseId: "215401",
    ProviderId: "240970",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019329001",
    NurseId: "215401",
    ProviderId: "224522",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019329001",
    NurseId: "215401",
    ProviderId: "212027",
    VisitTypeID: "1170024",
  },
  {
    DepartmentId: "1019329001",
    NurseId: "215401",
    ProviderId: "172746",
    VisitTypeID: "1170024",
  },
];
